<template>
  <div @click="onClick"
       :id="ques.index"
       class="question forbid"
       :class="{'analysis':hasAnalysis}">
    <div class="icon-result icon-right"
         v-if="ques.is_right==1&&hasAnalysis&&ques.ques_type!=5">
      <i class="el-icon-check bold"></i>
    </div>
    <div class="icon-result icon-error"
         v-else-if="ques.is_right!=1&&hasAnalysis&&ques.ques_type!=5">
      <i class="el-icon-close bold"></i>
    </div>
    <div class="stem bold"
         :class="'stem'+ques.ques_type.toString()">
      <div class="num-type"
           v-if="ques.ques_type!=5">
        <span>{{ques.index}}.(</span>
        <span class="type">{{ques.ques_type_name}}</span>)
      </div>
      <div class="num-type"
           v-else>
        <span>{{ques.index}}.</span>
        <span class="type">{{ques.ques_type_name}}</span>
        <span>{{'（'+ques.score+"分）"}}</span>
      </div>
      <div class="rich-text"
           v-html="ques.ques_stem"
           v-if="ques.ques_type!=5"></div>
    </div>
    <div class="rich-text stem5"
         v-if="ques.ques_type==5"
         v-html="ques.ques_stem"></div>
    <div class="options">
      <el-radio-group v-model="answer[ques.id]"
                      v-if="ques.ques_type==1||ques.ques_type==3"
                      @change="onChange(ques)"
                      :disabled="readonly">
        <el-radio :label="option.id"
                  v-for="(option,index) in ques.ques_option"
                  :key="option.id">
          <div class="option">
            <span>{{chars[index+1]}}、</span>
            <div class="rich-text">
              <div v-html="option.content"></div>
              <div style="clear:both;"></div>
            </div>
          </div>
        </el-radio>
      </el-radio-group>
      <el-checkbox-group v-model="answer[ques.id]"
                         v-else-if="ques.ques_type==2"
                         @change="onChange(ques)"
                         :disabled="readonly">
        <el-checkbox v-for="(option,index) in ques.ques_option"
                     :label="option.id"
                     :key="option.id">
          <div class="option">
            <span>{{chars[index+1]}}、</span>
            <div class="rich-text">
              <div v-html="option.content"></div>
              <div style="clear:both;"></div>
            </div>
          </div>
        </el-checkbox>
      </el-checkbox-group>
      <div class="line"
           v-else-if="ques.ques_type==4">
        <div>
          <div class="line-title">题目</div>
          <div class="option"
               v-for="(leftOption,index) in ques.ques_option.left"
               :key="leftOption.id">
            <div class="stem">
              <span>{{index+1}})</span>
              <span v-html="leftOption.content"></span>
            </div>
            <div class="answer">
              <span>答案</span>
              <el-radio-group v-model="answer[ques.id][leftOption.id]"
                              :disabled="readonly">
                <el-radio :label="rightOption.id"
                          v-for="rightOption in ques.ques_option.right"
                          :key="rightOption.id"
                          @change="onChange(ques)">{{chars[rightOption.id]}}</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="line-title">选项</div>
          <div class="option"
               v-for="rightOption in ques.ques_option.right"
               :key="rightOption.id">
            <div class="num">选项{{chars[rightOption.id]}}</div>
            <div v-html="rightOption.content"></div>
          </div>
        </div>
      </div>
      <div class="subjective"
           v-else-if="ques.ques_type==5&&!readonly">
        <div class="tips">（提示：简答题只能查看不可作答）</div>
        <!-- <Editor
          :content.sync="answer[ques.id]"
          uploadUrl="/api/app/public/file"
        ></Editor> -->
      </div>
      <!-- 单选、多选、判断题显示确定按钮，点击解析、提问列表显示 -->
      <div v-if="$route.name!=='MemberHomeworkDo'">
        <div v-if="!$route.query.type&&!readonly&&ques.ques_type!=5"
           class="confirm">
        <el-button type="primary"
                   @click.stop="showAnalysis">确定</el-button>
        </div>
        <div v-if="!$route.query.type&&!readonly&&ques.ques_type==5"
            class="confirm showAnswer">
          <el-button type="primary"
                    @click.stop="showAnalysis">查看答案</el-button>
        </div>
      </div>
    </div>
    <slot name="changeQuestion"></slot>
    <div class="analysis clear"
         :class="'analysis'+ques.ques_type.toString()"
         v-if="hasAnalysis">
      <div class="answer clear"
           v-if="ques.ques_type!=5">
        <div class="f-left">
          <span>
            <span>正确答案：</span>
            <span class="primary-color">{{ques.answer}}</span>
          </span>
          <span class="user">
            <span>您的答案：</span>
            <span class="primary-color">{{ques.user_answer_label||ques.user_answer}}</span>
          </span>
        </div>
        <div class="f-right operate"
             v-if="showOperate">
          <div class="f-left"
               @click.stop="collect"
               v-if="ques.collect_id>0">
            <img src="../images/exam/point/collect-has@3x.png"
                 style="width:17px;" />
            <span class="primary-color">取消收藏</span>
          </div>
          <div class="f-left"
               @click.stop="collect"
               v-else>
            <img src="../images/exam/point/collect@2x.png"
                 style="width:17px;" />
            <span>收藏</span>
          </div>
          <div class="f-right"
               @click.stop="showCorrection=true">
            <img src="../images/exam/point/hand@2x.png"
                 style="width:15px;" />
            <span>纠错</span>
          </div>
        </div>
      </div>
      <!-- <div class="subjective-answer"
           v-else-if="ques.ques_type==5&&readonly">
        <div>您的答案：</div>
        <div v-if="ques.user_answer.length>0"
             v-html="(ques.user_answer_label||ques.user_answer)[0]['id']"></div>
      </div> -->
      <div class="subjective-answer"
           v-if="ques.ques_type==5&&ques.review_status==1"
           style="padding-top:24px;">
        <div>您的得分：</div>
        <div>{{ques.user_score}}</div>
      </div>
      <div class="detail" :style=" {marginTop: ques.ques_type == 5?'30px':0 }">
        <div>
          <label>考&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;点：</label>
          <span class="point"
                v-for="(p,index) in points"
                :key="index">{{p}}</span>
          <span v-if="points.length==0">暂无考点</span>
        </div>
        <div class="analysis-detail">
          <label>题目解析：</label>
          <div v-html="ques.ques_analysis"
               v-if="ques.ques_analysis"></div>
          <div v-else>暂无解析</div>
        </div>
        <div class="follow-apply" v-if="$route.name!=='MemberHomeworkDetail' && $route.name!=='MemberQuestionExamDetail'">
          <el-input type="textarea"
                    :rows="4"
                    placeholder="请输入提问内容"
                    v-model="content"></el-input>
          <el-button type="primary"
                     style="margin-top:18px;"
                     @click="add_ask()">提问</el-button>
          <div style="clear:both;"></div>
        </div>
        <!-- 问答 -->
        <div class="answer-box" v-if="$route.name!=='MemberHomeworkDetail'">
          <h3 class="answer-top-title">
            <span>全部回答</span>
          </h3>
          <div class="answer-detail"
               :class="index==answerList.length-1?'no_border':''"
               v-for="(item,index) in answerList"
               :key="index">
            <div class="answer-detail-top">
              <img class="answer-avatar"
                   :src="item.avatar" />
              <div class="answer-right"
                   style="width:655px">
                <h3>
                  {{item.nickname || item.real_name}}
                  <span @click.stop="triggerSonPause(item.id,index,1,false,item.is_like)">
                    <img v-if="item.is_like==1"
                         class="answer-pause"
                         src="@/assets/images/news/dianzan_active.png" />
                    <img v-else
                         class="answer-pause"
                         src="@/assets/images/news/dianzan@3x.png" />
                    {{item.like_number}}
                  </span>
                </h3>
                <p class="answer-time">{{item.created_at}}</p>
                <div>
                  <p class="answer-content">
                    <img class="tip_icon"
                         src="@/assets/images/question@2x.png"><span v-html="item.content"></span></p>
                  <div class="answer-img-box"
                       v-if="item.images != ''">
                    <div class="answer-img"
                         v-for="(son,index) in stringToArray(item.images)"
                         :key="index">
                      <img :src="son"
                           @click="showPreview(stringToArray(item.images),index)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="answer-detail-top"
                 v-if="item.a_content">
              <img class="answer-avatar"
                   :src="item.a_avatar" />
              <div class="answer-right"
                   style="width:655px">
                <h3>
                  {{item.a_real_name}}
                </h3>
                <p class="answer-time">{{item.a_created_at}}</p>
                <div>
                  <p class="answer-content">
                    <img class="tip_icon"
                         src="@/assets/images/answer@2x.png"><span v-html="item.a_content"></span></p>
                  <div class="answer-img-box"
                       v-if="item.a_images != ''">
                    <div class="answer-img"
                         v-for="(son,index) in stringToArray(item.a_images)"
                         :key="index">
                      <img :src="son"
                           @click="showPreview(stringToArray(item.a_images),index)" />
                    </div>
                  </div>
                  <div>
                    <el-button type="primary"
                               v-show="item.showBtn"
                               @click="triggerBtn(1,index)">追问</el-button>
                    <el-button style="margin-left:0"
                               v-show="!item.showBtn"
                               @click="triggerBtn(2,index)">取消</el-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- 追问 -->
            <div class="follow-box"
                 v-if="item.child.total>0">
              <div class="follow-item"
                   v-for="(son,sonIndex) in item.child.data"
                   :key="sonIndex">
                <div class="follow-avatar">
                  <img :src="son.avatar" />
                </div>
                <div class="follow-right">
                  <h3>
                    {{son.user_name}}
                    <span @click.stop="triggerSonPause(son.id,index,2,sonIndex,son.is_like)">
                      <img v-if="son.is_like==1"
                           class="follow-pause"
                           src="@/assets/images/news/dianzan_active.png"
                           alt />
                      <img v-else
                           class="follow-pause"
                           src="@/assets/images/news/dianzan@3x.png"
                           alt />
                      {{son.like_number}}
                    </span>
                  </h3>
                  <p class="follow-time">{{son.created_at}}</p>
                  <div class="flolow">
                    <p class="follow-content">
                      <span v-if="son.ait">回复<span class="ait">@{{son.ait}}</span></span>
                      <span v-html="son.content"></span>
                    </p>
                  </div>
                </div>
              </div>
              <el-pagination background
                             v-if="item.child.total>5"
                             @current-change="((val)=>{childCurrentChange(val,item,index)})"
                             :current-page="item.child.current_page"
                             :page-size="childLimit"
                             layout="prev, pager, next, total, jumper"
                             :total="item.child.total"></el-pagination>
              <div style="clear:both;"></div>
            </div>
            <!-- 追问回复框 -->
            <div class="follow-apply"
                 v-show="!item.showBtn">
              <el-input type="textarea"
                        :rows="4"
                        placeholder="请输入追问内容"
                        v-model="item.textarea"></el-input>
              <el-button type="primary"
                         style="margin-top:18px;"
                         @click="reApply(item,index)">追问</el-button>
              <div style="clear:both;"></div>
            </div>
          </div>
          <Empty v-if="answerList.length==0"
                 class="empty"
                 title="暂无提问！" />
          <el-pagination background
                         @current-change="handleCurrentChange"
                         :current-page="page"
                         :page-size="limit"
                         layout="prev, pager, next, total, jumper"
                         :total="total"
                         v-if="total>limit"></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="纠错"
               :visible.sync="showCorrection"
               width="644px"
               :center="true">
      <div class="form-item">
        <label>纠错类型：</label>
        <el-radio-group v-model="correction.type">
          <el-radio :label="1">错别字</el-radio>
          <el-radio :label="2">答案解析不匹配</el-radio>
          <el-radio :label="3">解析错误</el-radio>
          <el-radio :label="4">其他</el-radio>
        </el-radio-group>
      </div>
      <div class="form-item">
        <label>纠错内容：</label>
        <el-input type="textarea"
                  :rows="5"
                  placeholder="请输入题目纠错信息，最多不超过500个字"
                  v-model="correction.error_msg"
                  :maxlength="500"></el-input>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="default"
                   @click="showCorrection = false">取消</el-button>
        <el-button type="primary"
                   @click="submitCorrection">提交</el-button>
      </span>
    </el-dialog>
    <ImageViewer :isShow.sync="showImageViewer"
                 :images="images"></ImageViewer>
  </div>
</template>
<script>
import ImageViewer from "@/components/ImageViewer.vue";
import Editor from "@/components/Editor.vue";

export default {
  name: "ExamQuestion",
  components: { ImageViewer, Editor },
  props: {
    ques: {
      type: Object
    },
    answer: {
      type: Object
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hasAnalysis: {
      type: Boolean,
      default: false
    },
    showOperate: {
      type: Boolean,
      default: false
    },
    showScore: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      chars: { 1: "A", 2: "B", 3: "C", 4: "D", 5: "E", 6: "F" },
      points: [],
      showCorrection: false,
      correction: {
        type: "",
        error_msg: ""
      },
      images: [],
      showImageViewer: false,
      isAnswer: 0,
      answerList: [],
      total: 0,
      childLimit: 5,
      content: '',
      limit: 10,
      page: 1
    };
  },
  created () {
    let point = this.ques.point_name || this.ques.point;
    let points;
    if (point) {
      points = point.split(",");
    } else {
      points = [];
    }
    this.points = points;
    if (this.hasAnalysis) {
      this.getAnswerList();
    }
  },
  watch: {
    answer: {
      // deep: true,
      handler: function () {
        this.onChange(this.ques);
      }
    }
  },
  methods: {
    onChange (ques) {
      this.$emit("change", ques);
    },
    async submitCorrection () {
      if (this.correction.type === "") {
        this.$info("请选择纠错类型");
        return;
      }
      if (this.correction.error_msg === "") {
        this.$info("请输入纠错内容");
        return;
      }
      await this.$http.post("/api/app/myExam/correction", {
        ques_id: this.ques.id,
        type: this.correction.type,
        error_msg: this.correction.error_msg
      });
      this.showCorrection = false;
      this.$success("提交成功");
    },
    async collect () {
      if (this.ques.collect_id > 0) {
        await this.$http.put(
          "/api/app/collect/cancel/" + this.ques.collect_id + "/3"
        );
        this.ques.collect_id = null;
        this.$success("取消收藏成功");
      } else {
        this.ques.collect_id = await this.$http.post("/api/app/collect", {
          ques_id: this.ques.id,
          type: 3
        });
        this.$success("收藏成功");
      }
    },
    showAnalysis () {
      this.$emit('showAnalysis');
    },
    onClick (event) {
      let target = event.target;
      if (target.tagName.toLowerCase() == "img") {
        this.images = [target.src];
        this.showImageViewer = true;
      }
    },
    stringToArray (str) {
      if (str == "" || str == null || str == undefined) {
        return [];
      } else {
        let arr = str.split(",");
        return arr;
      }
    },
    add_ask () {
      if (!this.content) {
        this.$message.warning("请填写提问内容");
        return;
      }
      let param = {
        question_id: this.ques.ques_id, 
        content: this.content
      }
      if (this.$route.name == 'ExamPackagePracticeAnalysis') {
        param.exam_type = 2;
        param.exam_id = this.$route.query.paper_id
      }
      this.$http.post("/api/app/ask/zask", param).then(res => {
        this.content = '';
        this.getAnswerList();
      })
    },
    showPreview (list, index) {
      this.prviewList = list;
      this.isShowImageDialog = true;
    },
    getAnswerList () {
      if (!this.ques.ques_id) return;
      this.$http.get("/api/app/ask/zaskList", { question_id: this.ques.ques_id, page: this.page, limit: this.limit }).then(res => {
        res.list.forEach(item => {
          item.showBtn = true;
          item.textarea = '';
        })
        this.answerList = res.list;
        this.total = res.total;
      })
    },
    async triggerSonPause (_id, _index, _type, _sonIndex) {
      let data = await this.$http.post("/api/app/ask/clickLike", {
        ask_id: this.ques.ques_id,
        comment_id: _id,
        user_type: 1
      });
      let item = this.answerList[_index];
      if (_type == 1) {
        item.is_like = !this.answerList[_index].is_like;
        if (item.is_like) {
          item.like_number++;
        } else {
          item.like_number--;
        }
      } else {
        item.child.data[_sonIndex].is_like = !this.answerList[_index].child.data[_sonIndex].is_like;
        if (item.child.data[_sonIndex].is_like) {
          item.child.data[_sonIndex].like_number++;
        } else {
          item.child.data[_sonIndex].like_number--;
        }
      }
      this.$set(this.answerList, _index, item)
    },
    triggerBtn (_type, _index) {
      let item = this.answerList[_index];
      item.showBtn = _type == 1 ? false : true;
      // this.$set(this.answerList._index,item)
    },
    handleCurrentChange (page) {
      this.page = page;
      this.getAnswerList();
    },
    async childCurrentChange (page, item, index) {
      let data = await this.getChildAnswer(item.a_id, page);
      let fData = this.answerList[index];
      fData.child.data = data.list;
      // this.$set(this.answerList[index].child, 'current_pages', 1)
    },
    getChildAnswer (_id, _page) {
      let data = this.$http.get('/api/app/ask/zaskChildList', {
        id: _id,
        page: _page,
        limit: this.childLimit
      })
      return data;
    },
    async reApply (_item, index) {
      await this.$http.post('/api/app/ask/zaskQuestioning', {
        parent_id: _item.a_id,
        content: _item.textarea
      })
      await this.$success("追问成功");
      let data = await this.getChildAnswer(_item.a_id, 1);
      let fData = _item;
      fData.child = data;
      fData.showBtn = true;
      fData.textarea = '';
      fData.child.data = data.list;
    },
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
// @import "../css/question.scss";

.question {
  padding: 28px;
  border-bottom: 1px solid #e9ecec;
  position: relative;
  &:last-child {
    border-bottom: none;
  }
  &.analysis {
    .options {
      padding-left: 24px;
    }
  }
  .option {
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    display: flex;
  }
  .icon-result {
    position: absolute;
    left: 0;
    top: 100px;
    color: white;
    width: 32px;
    height: 32px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    font-size: 20px;
    text-align: center;
    line-height: 32px;
  }
  .icon-right {
    background-color: #30c596;
  }
  .icon-error {
    background-color: #ff5400;
  }
  .stem {
    display: flex;
    font-size: 18px;
    color: #333333;
    margin-bottom: 30px;
    &.stem5 {
      margin-bottom: 20px;
    }
    .num-type {
      white-space: nowrap;
    }
    .type {
      color: $primary-color;
    }
    .rich-text {
      margin-left: 5px;
    }
  }
  .rich-text.stem5 {
    margin-bottom: 40px;
  }
  /deep/ .el-radio-group {
    display: flex;
    flex-direction: column;
    .el-radio {
      display: flex;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  /deep/ .el-checkbox-group {
    display: flex;
    flex-direction: column;
    .el-checkbox {
      display: flex;
      margin-bottom: 30px;
      .el-checkbox__label {
        line-height: 1;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .line {
    display: flex;
    .line-title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .answer {
      display: flex;
      align-items: center;
      > span:first-child {
        margin-right: 24px;
        white-space: nowrap;
      }
    }
    .option {
      border: 1px solid rgba(217, 217, 217, 1);
      border-radius: 10px;
      padding: 20px;
      font-size: 16px;
      margin-bottom: 15px;
      display: block;
      .stem {
        display: flex;
        font-size: 16px;
        align-items: center;
        span:first-child {
          margin-right: 15px;
        }
      }
    }
    /deep/ .el-radio-group {
      display: block;
      .el-radio {
        display: inline-block;
        margin-bottom: 0;
      }
    }
    .right {
      margin-left: 15px;
      .option {
        display: flex;
        .num {
          border: 1px solid $primary-color;
          color: $primary-color;
          border-radius: 5px;
          margin-right: 10px;
          width: 78px;
          box-sizing: border-box;
          height: 35px;
          text-align: center;
          line-height: 35px;
          flex: 0 0 auto;
        }
        > div:last-child {
          flex-grow: 1;
        }
      }
    }
    > div {
      flex-grow: 1;
    }
  }
  .analysis {
    padding-top: 60px;
    .answer {
      background-color: #f7f7f7;
      padding: 16px 30px;
      color: #666666;
      .user {
        margin-left: 64px;
      }
    }
    &.analysis5 {
      padding-top: 0;
      .detail {
        padding-left: 0;
      }
    }
    .detail {
      padding: 24px 30px 40px 30px;
      font-size: 16px;
      > div:first-child {
        margin-bottom: 24px;
      }
      label {
        color: #666666;
        margin-right: 12px;
      }
      .point {
        background-color: $primary-color;
        color: white;
        border-radius: 4px;
        padding: 2px 16px;
        margin-right: 10px;
      }
      .analysis-detail {
        display: flex;
        > label {
          white-space: nowrap;
        }
      }
    }
  }
  .operate {
    font-size: 16px;
    color: #595959;
    > div {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        margin-right: 5px;
      }
    }
    .f-left {
      margin-right: 40px;
    }
  }
}
.form-item {
  &:first-child {
    align-items: center;
  }
  /deep/ .el-radio-group {
    display: block;
    .el-radio {
      display: inline-block;
      margin-bottom: 0;
    }
  }
}
.subjective-answer {
  display: flex;
  color: #666666;
  > div:first-child {
    margin-right: 12px;
  }
}
.tips {
  text-align: center;
  line-height: 40px;
  color: #7f7f7f;
}
.confirm {
  text-align: center;
  margin-top: 40px;
  button {
    width: 110px;
  }
}
.showAnswer {
  text-align: left;
}
.follow-apply {
  text-align: right;
  width: 720px;
  margin: 10px 0 10px 40px;
  /deep/.el-button {
    float: right;
  }
}
.answer-box {
  padding: 0 16px 0 25px;
  .answer-top-title {
    border-bottom: 1px solid #edf1f2;
    color: #333;
    font-size: 16px;
    span {
      display: inline-block;
      height: 100%;
      padding: 10px 20px;
      border-bottom: 2px solid #3b90ff;
    }
  }
  .answer-detail {
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #edf1f2;
    .answer-detail-top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .answer-avatar {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
      }
      .no-free {
        text-align: center;
      }
      .answer-right {
        width: 730px;
        h3 {
          color: #333;
          font-size: 16px;
          margin: 4px 0 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            color: #999;
            font-weight: normal;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          .answer-collect {
            width: 16px;
            height: 16px;
          }
          .answer-pause {
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
        }
        .answer-time {
          color: #999;
          font-size: 14px;
        }
      }
    }
    .answer-title {
      font-size: 16px;
      color: #333;
      line-height: 1.6;
      margin-top: 24px;
    }
    .answer-content {
      font-size: 14px;
      color: #666;
      line-height: 1.6;
      margin: 16px 0;
      .tip_icon {
        width: 18px;
        height: 18px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .answer-img-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      .answer-img {
        width: 170px;
        height: 170px;
        margin: 15px 32px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
  .no_border {
    border: none;
  }
  .answer-box:last-of-type .answer-detail {
    border: none;
  }
}
.follow-box {
  width: 760px;
  background-color: #fcfbfb;
  margin: 10px 0 10px 40px;
  .follow-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 10px 0;
    .follow-avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-around;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .follow-right {
      width: 580px;
      h3 {
        color: #333;
        font-size: 16px;
        margin: 4px 0 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          color: #999;
          font-weight: normal;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .follow-collect {
          width: 16px;
          height: 16px;
        }
        .follow-pause {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
      .follow-time {
        color: #999;
        font-size: 14px;
      }
      .follow-content {
        font-size: 16px;
        color: #333;
        line-height: 1.6;
        margin: 16px 0 0;
      }
    }
  }
  /deep/.el-pagination {
    margin: 0;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}
.tip_icon {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-right: 5px;
}
</style>
<style lang="scss">
.analysis-detail img {
  max-width: 100%;
}
</style>
